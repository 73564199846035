import { Injectable } from '@angular/core';
import {
  HttpRequest,
  HttpHandler,
  HttpEvent,
  HttpInterceptor,
} from '@angular/common/http';
import { catchError, Observable, throwError } from 'rxjs';
import { Router } from '@angular/router';
import { LoadingService } from '../services/services';

@Injectable()
export class JwtInterceptor implements HttpInterceptor {
  constructor(private router: Router, private loading: LoadingService) {}

  intercept(
    request: HttpRequest<unknown>,
    next: HttpHandler
  ): Observable<HttpEvent<unknown>> {
    if (!!localStorage.getItem('tokenLogin')) {
      request = request.clone({
        setHeaders: {
          Authorization: `Bearer ${localStorage.getItem('tokenLogin')}`,
        },
      });
    }

    return next.handle(request).pipe(
      catchError((error) => {
        if (error.status === 401) {
          this.remueveTodo();
        }

        return throwError(() => error);
      })
    );
  }

  remueveTodo() {
    localStorage.clear();
    this.router.navigateByUrl('/auth');
    this.loading.loading = false;
  }
}
