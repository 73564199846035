import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { AuthService } from './auth.service';
import { Observable, tap } from 'rxjs';

@Injectable({ providedIn: 'root' })
export class AuthLoginGuard {
  constructor(
    private authService: AuthService,
    private router: Router
  ) {}
  canActivate(): boolean {
    if (this.authService.validarToken()) {
      this.init();
      return false;
    } else {
      return true;
    }
  }
  canLoad(): Observable<boolean> | boolean {
    if (this.authService.validarToken()) {
      this.init();
      return false;
    } else {
      return true;
    }
  }
  init() {
    this.router.navigateByUrl('/dashboard');
  }
}
