import { environment } from 'src/environments/environment';
import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { map } from 'rxjs';
import { Login, RespLogin } from 'src/app/interfaces';

@Injectable({
  providedIn: 'root'
})
export class AuthLoginService {

  constructor( private http: HttpClient ) { }

  login(data: Login){
    return this.http.post<RespLogin>(`${environment.endpoint}auth/login`,data).pipe(
      map((data:RespLogin) => {
        return data;
      })
    );
  }
}
