import { HttpClient } from '@angular/common/http';
import { Injectable, computed, signal } from '@angular/core';
import { map } from 'rxjs';
import { Country } from 'src/app/interfaces';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class CountriesService {

  _countriesSignal = signal<Country[]>([]);
  _countries = computed(() => this._countriesSignal());
  get countries(): Country[] { return this._countries(); }
  set countries(value: Country[]) { this._countriesSignal.set(value) }


  constructor( private http: HttpClient ) { }

  getCountries(){
    const headers = { 'Access-Control-Allow-Origin': '*', 'Content-Type': 'application/json' }
    return this.http.get<Country[]>(`${environment.endpoint}country`, { headers }).pipe(
      map((data:Country[]) => {
        return data;
      })
    );
  }
}

