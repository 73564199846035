import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { HTTP_INTERCEPTORS, HttpClientModule } from '@angular/common/http';
import { ClipboardModule } from 'ngx-clipboard';
import { TranslateModule } from '@ngx-translate/core';
import { InlineSVGModule } from 'ng-inline-svg-2';
import { NgbDropdownModule, NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';

import { FormsModule } from '@angular/forms';
import { LoadingComponent } from './shared/loading/loading.component';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { NgxGpAutocompleteModule } from '@angular-magic/ngx-gp-autocomplete';
import { Loader } from '@googlemaps/js-api-loader';
import { JwtInterceptor } from './interceptors/jwt.interceptor';
import { AgGridModule } from 'ag-grid-angular';

import { ClientSideRowModelModule } from '@ag-grid-community/client-side-row-model';

import { ModuleRegistry } from '@ag-grid-community/core';

ModuleRegistry.registerModules([ClientSideRowModelModule]);

@NgModule({
  declarations: [AppComponent, LoadingComponent],
  imports: [
    MatProgressSpinnerModule,
    BrowserModule,
    BrowserAnimationsModule,
    TranslateModule.forRoot(),
    HttpClientModule,
    ClipboardModule,
    FormsModule,
    AppRoutingModule,
    NgbDropdownModule,
    InlineSVGModule.forRoot(),
    NgbModule,
    NgxGpAutocompleteModule,
    AgGridModule,
  ],
  providers: [
    {
      provide: HTTP_INTERCEPTORS,
      useClass: JwtInterceptor,
      multi: true,
    },
    {
      provide: Loader,
      useValue: new Loader({
        apiKey: 'AIzaSyBDseJJko8ruKTBRf8gOQAiH08c39m11XE',
        libraries: ['places'],
      }),
    },
  ],

  bootstrap: [AppComponent],
})
export class AppModule {}
