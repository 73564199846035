import { ChangeDetectionStrategy, Component, OnInit } from '@angular/core';
import { TranslationService } from './modules/i18n';
// language list
import { locale as enLang } from './modules/i18n/vocabs/en';
import { locale as chLang } from './modules/i18n/vocabs/ch';
import { locale as esLang } from './modules/i18n/vocabs/es';
import { locale as jpLang } from './modules/i18n/vocabs/jp';
import { locale as deLang } from './modules/i18n/vocabs/de';
import { locale as frLang } from './modules/i18n/vocabs/fr';
import { ThemeModeService } from './_metronic/partials/layout/theme-mode-switcher/theme-mode.service';
import { Router } from '@angular/router';
import { CountriesService, LoadingService } from './services/services';
import { Category, Country } from './interfaces';
import { CategoryService } from './services/services/category.service';

@Component({
  // tslint:disable-next-line:component-selector
  // eslint-disable-next-line @angular-eslint/component-selector
  selector: 'body[root]',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AppComponent implements OnInit {
  constructor(
    public loading: LoadingService,
    private translationService: TranslationService,
    private modeService: ThemeModeService,
    private router: Router,
    private countryService: CountriesService,
    private categoryService: CategoryService
  ) {
    // register translations
    this.translationService.loadTranslations(
      enLang,
      chLang,
      esLang,
      jpLang,
      deLang,
      frLang
    );
  }

  ngOnInit() {
    this.modeService.init();
    this.getCountries();
    this.getCategories();
    this.router.navigateByUrl('/dashboard');
  }
  getCountries() {
    this.countryService.getCountries().subscribe({
      next: async (data: Country[]) => {
        this.countryService.countries = data;
      },
      error: () => {
        this.countryService.countries = [];
      },
    });
  }
  getCategories() {
    // this.categoryService.getCategories().subscribe({
    //   next: async (data:Category[]) => {
    //     this.categoryService.categories = data;
    //   },error: () => {
    //     this.categoryService.categories = [];
    //   }
    // })
  }
}
