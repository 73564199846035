import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { map } from 'rxjs';
import { environment } from 'src/environments/environment';
import { NewSuggestion, RespBusinessSuggestion } from '../../interfaces';

@Injectable({
  providedIn: 'root'
})
export class SuggestionService {

  constructor( private http: HttpClient ) { }

  getNuoviSuggestion(){
    let headers = {'Content-Type': 'application/json', 'Authorization': `Bearer ${localStorage.getItem('tokenLogin')}` }
    return this.http.get<RespBusinessSuggestion>(`${environment.endpoint}business-suggestion/find-all/new/false`, { headers }).pipe(
      map((data:RespBusinessSuggestion) => {
        return data;
      })
    );
  }

  getRespintiSuggestion(){
    let headers = {'Content-Type': 'application/json', 'Authorization': `Bearer ${localStorage.getItem('tokenLogin')}` }
    return this.http.get<RespBusinessSuggestion>(`${environment.endpoint}business-suggestion/find-all/inactive/false`, { headers }).pipe(
      map((data:RespBusinessSuggestion) => {
        return data;
      })
    );
  }

  getApprovatiSuggestion(){
    let headers = {'Content-Type': 'application/json', 'Authorization': `Bearer ${localStorage.getItem('tokenLogin')}` }
    return this.http.get<RespBusinessSuggestion>(`${environment.endpoint}business-suggestion/find-all/active/false`, { headers }).pipe(
      map((data:RespBusinessSuggestion) => {
        return data;
      })
    );
  }

  setImage(file: File, path: string) {
    let data = new FormData()
    data.append('file',file)
    data.append('path','passaparola/busines-suggestions/'+path)
    let headers = new HttpHeaders({'Authorization': `Bearer ${localStorage.getItem('tokenLogin')}`});
    return this.http.post<string>(`${environment.endpoint}storage`,data, { headers }).pipe(
      map((data:string) => {
        return data;
      })
    );
  }

  newSuggestion(data: NewSuggestion){
    let headers = {'Content-Type': 'application/json', 'Authorization': `Bearer ${localStorage.getItem('tokenLogin')}` }
    return this.http.post<any>(`${environment.endpoint}business-suggestion`, data, { headers }).pipe(
      map((data:any) => {
        return data;
      })
    );
  }
}
