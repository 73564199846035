import { HttpClient, HttpHeaders } from '@angular/common/http';
import { computed, Injectable, signal } from '@angular/core';
import { map } from 'rxjs';
import { Banners, SetBanner, SetBannerFourth, SetBannerMain, SetBannerSecondary, SetBannerThird } from 'src/app/interfaces';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class BannersService {

  _bannersSignal = signal<Banners[]>([]);
  _banners = computed(() => this._bannersSignal());
  get banners(): Banners[] { return this._banners(); }
  set banners(value: Banners[]) { this._bannersSignal.set(value) }

  _bannersMainSignal = signal<Banners[]>([]);
  _bannersMain = computed(() => this._bannersMainSignal());
  get bannersMain(): Banners[] { return this._bannersMain(); }
  set bannersMain(value: Banners[]) { this._bannersMainSignal.set(value) }

  _bannersSecundrySignal = signal<Banners[]>([]);
  _bannersSecundry = computed(() => this._bannersSecundrySignal());
  get bannersSecundry(): Banners[] { return this._bannersSecundry(); }
  set bannersSecundry(value: Banners[]) { this._bannersSecundrySignal.set(value) }

  _bannersThirdSignal = signal<Banners[]>([]);
  _bannersThird = computed(() => this._bannersThirdSignal());
  get bannersThird(): Banners[] { return this._bannersThird(); }
  set bannersThird(value: Banners[]) { this._bannersThirdSignal.set(value) }

  _bannersFourthSignal = signal<Banners[]>([]);
  _bannersFourth = computed(() => this._bannersFourthSignal());
  get bannersFourth(): Banners[] { return this._bannersFourth(); }
  set bannersFourth(value: Banners[]) { this._bannersFourthSignal.set(value) }

  constructor( private http: HttpClient ) { }

  getBanners(){
    let headers = new HttpHeaders({'Authorization': `Bearer ${localStorage.getItem('tokenLogin')}`, 'Access-Control-Allow-Origin': '*', 'Content-Type': 'application/json'});
    return this.http.get<Banners[]>(`${environment.endpoint}banner/banners/find-all`, { headers }).pipe(
      map((data:Banners[]) => {
        return data;
      })
    );
  }
  getBannersMain(){
    let headers = new HttpHeaders({'Authorization': `Bearer ${localStorage.getItem('tokenLogin')}`, 'Access-Control-Allow-Origin': '*', 'Content-Type': 'application/json'});
    return this.http.get<Banners[]>(`${environment.endpoint}banner/carousel/main`, { headers }).pipe(
      map((data:Banners[]) => {
        return data;
      })
    );
  }
  getBannersSecundary(){
    let headers = new HttpHeaders({'Authorization': `Bearer ${localStorage.getItem('tokenLogin')}`, 'Access-Control-Allow-Origin': '*', 'Content-Type': 'application/json'});
    return this.http.get<Banners[]>(`${environment.endpoint}banner/carousel/secondary`, { headers }).pipe(
      map((data:Banners[]) => {
        return data;
      })
    );
  }
  getBannersThird(){
    let headers = new HttpHeaders({'Authorization': `Bearer ${localStorage.getItem('tokenLogin')}`, 'Access-Control-Allow-Origin': '*', 'Content-Type': 'application/json'});
    return this.http.get<Banners[]>(`${environment.endpoint}banner/carousel/third`, { headers }).pipe(
      map((data:Banners[]) => {
        return data;
      })
    );
  }
  getBannersFourth(){
    let headers = new HttpHeaders({'Authorization': `Bearer ${localStorage.getItem('tokenLogin')}`, 'Access-Control-Allow-Origin': '*', 'Content-Type': 'application/json'});
    return this.http.get<Banners[]>(`${environment.endpoint}banner/carousel/fourth`, { headers }).pipe(
      map((data:Banners[]) => {
        return data;
      })
    );
  }


  updateBanner(banner: SetBanner, id: string){
    let headers = new HttpHeaders({'Authorization': `Bearer ${localStorage.getItem('tokenLogin')}`, 'Access-Control-Allow-Origin': '*', 'Content-Type': 'application/json'});
    return this.http.patch<Banners>(`${environment.endpoint}banner/${id}`, banner ,{ headers }).pipe(
      map((data:Banners) => {
        return data;
      })
    );
  }
  updateBannerMain(banner: SetBannerMain, id: string){
    let headers = new HttpHeaders({'Authorization': `Bearer ${localStorage.getItem('tokenLogin')}`, 'Access-Control-Allow-Origin': '*', 'Content-Type': 'application/json'});
    return this.http.patch<Banners>(`${environment.endpoint}banner/${id}`, banner ,{ headers }).pipe(
      map((data:Banners) => {
        return data;
      })
    );
  }
  updateBannerSecondary(banner: SetBannerSecondary, id: string){
    let headers = new HttpHeaders({'Authorization': `Bearer ${localStorage.getItem('tokenLogin')}`, 'Access-Control-Allow-Origin': '*', 'Content-Type': 'application/json'});
    return this.http.patch<Banners>(`${environment.endpoint}banner/${id}`, banner ,{ headers }).pipe(
      map((data:Banners) => {
        return data;
      })
    );
  }
  updateBannerThird(banner: SetBannerThird, id: string){
    let headers = new HttpHeaders({'Authorization': `Bearer ${localStorage.getItem('tokenLogin')}`, 'Access-Control-Allow-Origin': '*', 'Content-Type': 'application/json'});
    return this.http.patch<Banners>(`${environment.endpoint}banner/${id}`, banner ,{ headers }).pipe(
      map((data:Banners) => {
        return data;
      })
    );
  }
  updateBannerFourth(banner: SetBannerFourth, id: string){
    let headers = new HttpHeaders({'Authorization': `Bearer ${localStorage.getItem('tokenLogin')}`, 'Access-Control-Allow-Origin': '*', 'Content-Type': 'application/json'});
    return this.http.patch<Banners>(`${environment.endpoint}banner/${id}`, banner ,{ headers }).pipe(
      map((data:Banners) => {
        return data;
      })
    );
  }






  setBanner(banner: SetBanner){
    let headers = new HttpHeaders({'Authorization': `Bearer ${localStorage.getItem('tokenLogin')}`, 'Access-Control-Allow-Origin': '*', 'Content-Type': 'application/json'});
    return this.http.post<Banners>(`${environment.endpoint}banner`, banner ,{ headers }).pipe(
      map((data:Banners) => {
        return data;
      })
    );
  }
  deleteBanner(id: string) {
    let headers = new HttpHeaders({'Authorization': `Bearer ${localStorage.getItem('tokenLogin')}`, 'Access-Control-Allow-Origin': '*', 'Content-Type': 'application/json'});
    return this.http.delete<string>(`${environment.endpoint}banner/${id}`,{ headers }).pipe(
      map((data:string) => {
        return data;
      })
    );
  }
  setImage(file: File, path: string) {
    let data = new FormData()
    data.append('file',file)
    data.append('path','passaparola/busines-suggestions/'+path)
    let headers = new HttpHeaders({'Authorization': `Bearer ${localStorage.getItem('tokenLogin')}`});
    return this.http.post<string>(`${environment.endpoint}storage`,data, { headers }).pipe(
      map((data:string) => {
        return data;
      })
    );
  }
}
