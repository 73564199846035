import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable, computed, signal } from '@angular/core';
import { catchError, map, Observable, throwError } from 'rxjs';
import { Category } from 'src/app/interfaces';
import { environment } from 'src/environments/environment';
import Swal from 'sweetalert2';

interface Metadata {
  total: number;
  page: number;
  lastPage: number;
}

@Injectable({
  providedIn: 'root',
})
export class CategoryService {
  _categoriesSignal = signal<Category[]>([]);
  _categories = computed(() => this._categoriesSignal());
  get categories(): Category[] {
    return this._categories();
  }
  set categories(value: Category[]) {
    this._categoriesSignal.set(value);
  }

  constructor(private http: HttpClient) {}

  getCategories(queryParams?: {
    [key: string]: string | string[];
  }): Observable<{ data: Category[]; metadata: Metadata }> {
    let params = new HttpParams();

    if (queryParams) {
      for (const key in queryParams) {
        if (queryParams[key]) {
          params = params.set(key, queryParams[key] as string);
        }
      }
    }

    return this.http
      .get<{ data: Category[]; metadata: Metadata }>(
        `${environment.endpoint}company-category`,
        { params }
      )
      .pipe(
        map((data) => {
          return data;
        })
      );
  }

  getCategoryById(id: string) {
    return this.http.get<Category>(
      `${environment.endpoint}company-category/${id}`
    );
  }

  getParentCategories(
    id: string,
    queryParams?: {
      [key: string]: string | string[];
    }
  ): Observable<Category[]> {
    let params = new HttpParams();

    if (queryParams) {
      for (const key in queryParams) {
        if (queryParams[key]) {
          params = params.set(key, queryParams[key] as string);
        }
      }
    }

    return this.http
      .get<Category[]>(
        `${environment.endpoint}company-category/find-all/with-children/${id}`,
        { params }
      )
      .pipe(map((data) => data[0].children!));
  }

  createCategory(category: Category) {
    return this.http.post<Category>(
      `${environment.endpoint}company-category`,
      category
    );
  }

  updateCategory(category: Category, id: string) {
    return this.http
      .patch<Category>(
        `${environment.endpoint}company-category/${id}`,
        category
      )
      .pipe(
        catchError((error) => {
          Swal.fire({
            title: 'Erro!',
            text: error.error.message,
            icon: 'error',
            confirmButtonText: 'Ok',
          });

          return throwError(() => error);
        })
      );
  }

  deleteCategory(id: string) {
    return this.http
      .delete(`${environment.endpoint}company-category/${id}`)
      .pipe(
        catchError((error) => {
          Swal.fire({
            title: 'Erro!',
            text: error.error.message,
            icon: 'error',
            confirmButtonText: 'Ok',
          });

          return throwError(() => error);
        })
      );
  }
}
