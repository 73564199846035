import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Router, RouterStateSnapshot } from '@angular/router';
import { AuthService } from './auth.service';
import { Observable, tap } from 'rxjs';

@Injectable({ providedIn: 'root' })
export class AuthGuard {
  constructor(
    private authService: AuthService,
    private router: Router
  ) {}
  canActivate(): boolean {
    if (!this.authService.validarToken()) {
      this.init();
      return true;
    } else {
      return true;
    }
  }
  canLoad(): Observable<boolean> | boolean {
    if (!this.authService.validarToken()) {
      this.init();
      return false;
    } else {
      return true;
    }
  }
  init() {
    this.router.navigateByUrl('/auth/login');
    localStorage.clear();
  }
}
