import {
  HttpClient,
  HttpContext,
  HttpErrorResponse,
} from '@angular/common/http';
import { Injectable } from '@angular/core';
import { catchError, map, Observable, throwError } from 'rxjs';
import { Company } from 'src/app/interfaces';
import { CompanySeat } from 'src/app/interfaces/company_seat';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root',
})
export class CompanyService {
  constructor(private http: HttpClient) {}

  getCompanies() {
    let headers = {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${localStorage.getItem('tokenLogin')}`,
    };
    return this.http
      .get<Company[]>(`${environment.endpoint}company`, { headers })
      .pipe(
        map((data: Company[]) => {
          return data;
        })
      );
  }

  getCompany(id: string): Observable<Company> {
    return this.http.get<Company>(`${environment.endpoint}company/${id}`);
  }

  // getWalletsByCompanyID(id: string): Observable<any[]> {
  //   const url = `${environment.endpointWallet}/wallet/find-wallets-by-user-id/${id}`;

  //   return this.http.get<any>(url);
  // }

  getAllSeat({
    offset,
    limit,
    categoryIds,
    keyword,
    type = 'all',
    isSuggested = false,
  }: any) {
    return this.http
      .post<CompanySeat[]>(
        `${environment.endpoint}company-profile/seat/find/all`,
        categoryIds,
        {
          params: { offset, limit, keyword, type, isSuggested },
        }
      )
      .pipe(
        map((response: any) => {
          return response;
        })
      )
      .pipe(
        catchError((error: HttpErrorResponse) => {
          return throwError(() => error);
        })
      );
  }
}
